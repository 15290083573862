/* eslint-disable max-len */
import { getMediaImageData } from '@innedit/innedit-react';
import objectHash from 'object-hash';
import React, { FC } from 'react';

const Photo: FC<{
  media: {
    origin: string;
    pathname: string;
    height?: number;
    width?: number;
  };
  name?: string;
  ratio: number;
}> = ({ media, name, ratio }) => {
  const transformations = [`c_contain,b_fcfbf8,r_${ratio}`];

  const image = getMediaImageData({
    media,
    transformations,
    breakpoints: [150, 240, 320, 480, 640],
    maxWidth: 640,
  });

  if (!image) {
    return null;
  }

  const srcSets = image.images.srcSet.split(',');

  return (
    <picture>
      {undefined !== srcSets &&
        srcSets.map((srcSet: string) => {
          const [src, width] = srcSet.split(' ');
          const pixel = width.substr(0, width.length - 1);

          return (
            <source
              key={objectHash({ srcSet, type: 'image/jpeg' })}
              media={`(max-width: ${pixel}px)`}
              srcSet={src}
              type="image/jpeg"
            />
          );
        })}
      {undefined !== srcSets &&
        srcSets.map((srcSet: string) => {
          const [, width] = srcSet.split(' ');
          const pixel = width.substr(0, width.length - 1);

          return (
            <source
              key={objectHash({ srcSet, type: 'image/webp' })}
              media={`(max-width: ${pixel}px)`}
              srcSet={`${media.origin}/c_contain,b_fcfbf8,r_${ratio},fm_webp,w_${pixel}/${media.pathname}`}
              type="image/webp"
            />
          );
        })}

      <img
        alt={name}
        height={media.height}
        sizes={image.images.sizes}
        src={image.images.src}
        srcSet={image.images.srcSet}
        width={ratio && media.height ? media.height * ratio : media.width}
      />
    </picture>
  );
};

export default Photo;
