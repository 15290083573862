/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../../props/Icon';

const IconSearch: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 16 16" width={size}>
    <g transform="matrix(0.999153,0,0,1.02746,0.361166,-13.8823)">
      <g transform="matrix(0.836885,0,0,0.836885,1.02202,3.87089)">
        <path
          d="M6.5,26.58C3.15,26.58 0.42,23.85 0.42,20.5C0.42,17.15 3.15,14.42 6.5,14.42C9.85,14.42 12.58,17.15 12.58,20.5C12.58,23.85 9.85,26.58 6.5,26.58ZM6.5,15.58C3.78,15.58 1.58,17.79 1.58,20.5C1.58,23.21 3.79,25.42 6.5,25.42C9.21,25.42 11.42,23.21 11.42,20.5C11.42,17.79 9.22,15.58 6.5,15.58Z"
          fill={color || 'currentColor'}
        />
      </g>
      <g transform="matrix(0.591761,-0.591761,0.591761,0.591761,-11.6825,17.9728)">
        <rect
          fill={color || 'currentColor'}
          height="6.36"
          width="1.15"
          x="12.18"
          y="23.57"
        />
      </g>
    </g>
  </svg>
);

export default IconSearch;
