import { ProduitModel } from '@innedit/innedit-react';
import { CategorieNode, ProduitNode } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import objectHash from 'object-hash';
import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import slug from 'slug';

import Pagination from '../Pagination';
import Item from './Item';
import Navigation from './Navigation';
import Recherche from './Recherche';

interface ProduitsProps {
  categories?: (CategorieNode & {
    traductions?: { en?: { libelle?: string } };
  })[];
  hideCategories?: boolean;
  hideHeader?: boolean;
  hidePagination?: boolean;
  hideSearch?: boolean;
  items?: ProduitNode[];
  itemsClassName?: string;
  page?: number;
  pathname: string;
  queryPath?: string;
  totalCount?: number;
}

const Produits: FC<ProduitsProps> = function ({
  categories,
  hideCategories = false,
  hideHeader = false,
  hidePagination = false,
  hideSearch = false,
  items,
  itemsClassName,
  page = 0,
  pathname,
  queryPath,
  totalCount = 0,
}) {
  const nbParPage =
    parseInt(String(process.env.GATSBY_HITS_PER_PAGE), 10) || 30;
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(
    queryPath?.split('-').join(' '),
  );
  const [produits, setProduits] = useState<any[] | undefined>(items);
  const [nbHits, setNbHits] = useState<number>(totalCount);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let isSubscribed = true;
    if (query) {
      setLoading(true);
      ProduitModel.search(query, {
        nbParPage,
        page,
        language: 'en',
        wheres: {
          hidden: false,
          qtyAvailable: {
            operator: '>',
            value: 0,
          },
        },
      })
        .then(value => {
          if (isSubscribed) {
            if (value.hits) {
              setNbHits(value.nbHits);
              setProduits(value.hits);
            }

            setLoading(false);
          }

          return true;
        })
        .catch(error => {
          if (isSubscribed) {
            setLoading(false);
          }

          console.error(error);
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [nbParPage, page, query]);

  const handleOnSubmit = async (values: { q: string }) => {
    const path = slug(values.q);
    window.history.replaceState(
      { path },
      '',
      t('pages.recherche.pathname', { path }),
    );

    await navigate(t('pages.recherche.pathname', { path }));
    setQuery(values.q);
  };

  return (
    <div className="flex flex-col space-y-12">
      {!hideHeader && (
        <div>
          <div className="min-w-80 sticky top-6">
            {!hideSearch && (
              <Recherche
                initialValue={queryPath?.split('-').join(' ')}
                onSubmit={handleOnSubmit}
              />
            )}

            {!hideCategories && categories && (
              <Navigation
                items={categories.map(child => {
                  const titleEn = String(child.traductions?.en?.libelle ?? child.libelle);

                  return ({
                    label: titleEn,
                    to: `${t('categorie.pathname')}${slug(titleEn)}/`,
                  })
                })}
              />
            )}
          </div>
        </div>
      )}

      <div className="">
        {!loading && 0 === nbHits && (
          <p className="text-center text-sm">
            {t(`product.infos.empty.label`)}
          </p>
        )}
        {loading && <p className="text-center text-sm">{t('loading')}</p>}

        {!loading && nbHits > 0 && (
          <>
            <div className="flex justify-between items-center">
              {!hideHeader && (
                <span className="text-sm">
                  {t(`products.infos.item.label`, { count: nbHits })}
                </span>
              )}

              {!hidePagination && (
                <Pagination
                  hitsPerPage={nbParPage}
                  nbHits={nbHits}
                  page={page}
                  pathname={pathname}
                />
              )}
            </div>

            <ul
              className={
                itemsClassName ??
                'my-12 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 gap-y-12 md:gap-12'
              }
            >
              {produits &&
                produits.map(data => (
                  <Item key={objectHash(data)} data={data} />
                ))}
            </ul>

            {!hidePagination && (
              <Pagination
                hitsPerPage={nbParPage}
                nbHits={nbHits}
                page={page}
                pathname={pathname}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps: ProduitsProps, nextProps: ProduitsProps) =>
  prevProps.items?.length === nextProps.items?.length &&
  prevProps.pathname === nextProps.pathname &&
  prevProps.queryPath === nextProps.queryPath &&
  prevProps.totalCount === nextProps.totalCount;

export default memo(Produits, propsAreEqual);
