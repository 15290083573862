import { Link } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Photo from './Photo';

interface ItemProps {
  data: any;
}

const Item: FC<ItemProps> = function ({ data: produit }) {
  const ratio = 1.1;
  const { t } = useTranslation();

  const {
    hasInventory,
    qtyAvailable,
    qtyReserved,
    name,
    pathname,
    traductions,
  } = produit;

  const medias: unknown[] = produit.medias as unknown[];

  if (produit.hidden) {
    return null;
  }
  const to = `/product/${pathname}/`;

  return (
    <li className="mb-10">
      <Link className="block" to={to}>
        <div className="bg-light-200 h-full">
          {medias && medias.length > 0 && (
            <Photo
              media={
                medias[0] as {
                  origin: string;
                  pathname: string;
                  height?: number;
                  width?: number;
                }
              }
              name={traductions?.en?.name ?? name}
              ratio={ratio}
            />
          )}
        </div>
      </Link>

      <h2 className="mt-2 flex overflow-hidden space-x-10 overflow-ellipsis items-center">
        <Link className="truncate flex-auto" to={to}>
          {traductions?.en?.name ?? name}
        </Link>
        {hasInventory &&
          qtyAvailable > 0 &&
          qtyReserved > 0 &&
          qtyAvailable === qtyReserved && (
            <span className="text-sm flex-1 text-right text-gray-400">
              {t('products.item.is-reserved.label')}
            </span>
          )}
        {hasInventory && 0 === qtyAvailable && (
          <span className="text-sm flex-1 text-right text-red-800">
            {t('products.item.is-sold.label')}
          </span>
        )}
      </h2>
    </li>
  );
};

export default Item;
