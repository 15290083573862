import { CategorieNode, ProduitNode } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../components/Content';
import Layout from '../components/Layout';
import Produits from '../components/Produits';

const List: FC<
  PageProps<
    {
      categories: {
        edges: { node: CategorieNode }[];
      };
      category: CategorieNode & {
        children: CategorieNode[];
        parent: CategorieNode & { traductions?: { en?: { libelle?: string } } };
      };
      items: {
        edges: { node: ProduitNode }[];
        totalCount: number;
      };
    },
    { page: number; title: string }
  >
> = function (props) {
  const {
    data,
    params,
    params: { query: queryPath },
    location: { pathname },
    pageContext,
    pageContext: { title },
  } = props;
  const { t } = useTranslation();
  let produits;
  let totalCount = 0;
  if (data && data.items) {
    const { edges } = data.items;
    totalCount = data.items.totalCount;
    produits = edges.map(edge => edge.node);
  }

  const page = parseInt(String(pageContext.page || params.page || 0), 10);

  let categories;
  if (data && data.categories) {
    const { edges } = data.categories;
    categories = edges.map(edge => edge.node);
  }

  const category = (data && data.category) || undefined;

  const breakcrumbs = [];
  if (pathname !== t('pages.inventaire.pathname')) {
    breakcrumbs.push(
      category && category.parent
        ? {
            mobile: true,
            to: `${t(
              'categorie.pathname',
            )}${category.parent.id.toLowerCase()}/`,
            value: String(
              category.parent.traductions?.en?.libelle ??
                category.parent.libelle,
            ),
          }
        : {
            mobile: true,
            to: t('pages.inventaire.pathname'),
            value: t('pages.inventaire.title'),
          },
    );
  }

  return (
    <Layout
      breadcrumbs={breakcrumbs}
      breadcrumbsTitle={breakcrumbs.length > 0 ? title : undefined}
      head={{
        title,
        meta: [
          {
            content: 'Liste des produits disponible',
            name: 'description',
          },
        ],
      }}
      pathname={pathname}
      showTitle
      title={queryPath ? 'pages.recherche.title' : title}
    >
      <Content>
        <Produits
          categories={
            category && category.children ? category.children : categories
          }
          hideCategories={false}
          items={produits}
          page={page ? Number(page) : 0}
          pathname={pathname}
          queryPath={queryPath}
          totalCount={totalCount}
        />
      </Content>
    </Layout>
  );
};

export default List;
