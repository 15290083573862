import React, { FC, KeyboardEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconSearch from '../../icons/Search';
import Button from '../Button';
import Control from '../Form/Field/Control';

interface RechercheProps {
  className?: string;
  initialValue?: string;
  onSubmit: (values: { q: string }) => void;
}

const Recherche: FC<RechercheProps> = function ({
  className,
  initialValue,
  onSubmit,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(initialValue || '');

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if ('Enter' === event.key) {
      onSubmit({ q: value });
    }
  };

  const handleOnSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    onSubmit({ q: value });
  };

  return (
    <div className={className || 'flex'}>
      <Control
        as="input"
        className="rounded-none"
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={t('products.form.recherche.q.placeholder')}
        type="text"
        value={value}
      />
      <Button
        aria-label="Lancer la recherche"
        disabled={value === initialValue}
        iconLeft={IconSearch}
        iconSize={24}
        onClick={handleOnSubmit}
        status="primary"
        type="button"
      />
    </div>
  );
};

export default Recherche;
